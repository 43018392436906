









































































import {Component, Vue, Prop, Watch, Ref} from "vue-property-decorator";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import {UserDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  name: "AssociateRegisterAccount",
  components: {PagedTableView}
})
export default class AssociateRegisterAccount extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: number;

  @Prop({required: true})
  registerAccountId!: number;

  show = false;
  defaultData: UserDto = {};
  form: UserDto = {...this.defaultData};

  queryForm: any = {
    name: undefined,
    surname: undefined,
    phoneNumber: undefined,
    isOnlyMembership: true,
    mode: "foundation"
  };

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });
      await this.fetchData(this.queryForm);
    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.user.getAll(params);
  }

  handleAssociate(row: UserDto) {
    this.$confirm("您确定关联此注册用户吗？", "提示").then(() => {
      api.donor.associatedRegisterAccount({
        body: {
          id: this.dataId,
          registerAccountId: row.id
        }
      }).then(() => {
        this.$message.success("关联成功");
        this.show = false;
      })
    })
  }

  handleCancelAssociate(row: UserDto) {
    api.donor.cancelAssociatedRegisterAccount({
      body: {
        id: this.dataId,
      }
    }).then(() => {
      this.$message.success("取消关联成功");
      this.show = false;
    })
  }

  cancel() {
    this.show = false;
  }

}
