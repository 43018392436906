



























































































import PagedTableView from "@/components/PagedTableView/index.vue";
import {Vue, Component, Watch} from "vue-property-decorator";
import api from "@/api";
import {SelectListItem, StudentDonorDto, StudentDonorType} from "@/api/appService";
import ExportButton from "@/components/ExportButton/index.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";
import AssociateRegisterAccount from "@/views/studentAid/donor/components/associateRegisterAccount.vue"

@Component({
  name: "DonorList",
  components: {PagedTableView, ExportButton, ImportExcel, AssociateRegisterAccount},
  filters: {
    stateFormat(isActive: boolean) {
      return isActive ? "已激活" : "已禁用";
    }
  }
})
export default class DonorList extends Vue {
  queryForm = {
    //捐助方名称
    name: "",
    //联系人手机号
    mobilePhone: "",
    //联系人的邮箱
    emailAddress: "",
    //捐助方类型
    type: ""
  };
  StudentDonorTypeList: SelectListItem[] = [];
  dataId = 0;
  registerAccountId = 0;

  async created() {
    this.StudentDonorTypeList = await this.getStudentDonorTypeList();
  }

  fetchData(params: any) {
    return api.donor.getAll(params);
  }

  getStudentDonorTypeList() {
    return api.enumService.getValues({typeName: "StudentDonorType"});
  }

  addDonor() {
    this.$router.push({
      name: "donorsAdd",
      params: {}
    });
  }

  handleDetail(index: number, row: StudentDonorDto) {
    this.$router.push({
      name: "donorsDetail",
      params: {
        donorId: row.id!.toString()
      }
    });
  }

  handleEdit(index: number, row: StudentDonorDto) {
    this.$router.push({
      name: "donorsEdit",
      params: {
        donorId: row.id!.toString()
      }
    });
  }

  handleRegisterAccount(index: number, row: StudentDonorDto) {
    this.dataId = Number(row.id);
    this.registerAccountId = Number(row.registerAccountId);
    (this.$refs.registerAccount as any).show = true;
  }

  handleDelete(index: number, row: StudentDonorDto) {
    this.$confirm("你确定删除吗?", "提示").then(() => {
      api.donor.delete({
        id: row.id
      }).then(() => {
        this.$message.success("删除成功");
      });
    });

  }

  handleDisabled(index: number, row: StudentDonorDto) {
    api.donor.disable({
      body: {
        id: row.id
      }
    }).then(() => {
      this.$message.success("修改成功");
    });
  }

  handleActive(index: number, row: StudentDonorDto) {
    api.donor.active({
      body: {
        id: row.id
      }
    }).then(() => {
      this.$message.success("修改成功");
    });
  }

  typeFormat(row: StudentDonorDto) {
    if (row.type === "Enterprise") {
      return "企业";
    } else if (row.type === "Personal") {
      return "个人";
    }
  }
}
